<script>
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import { integer } from "@vuelidate/validators";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import Editor from "@tinymce/tinymce-vue";

export default {
  props: {
    idsoal: integer,
    currentPage: integer,
    idJadwalPeserta: String,
    idJadwal: String,
    refreshTable: Function,
  },
  components: {
    Editor,
    Datatable,
    Pagination,
  },
  data() {
    let sortOrders = []; // select sortOrder

    // definisi kolom yang dipakai
    let columns = [
      {
        width: "5%",
        label: "No",
        name: "idjadwal",
      },
      { width: "45%", label: "Tanggal Simpan", name: "created_at" },
      { width: "50%", label: "Aksi", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
    ];

    // cek sorting
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });
    return {
      formData: {
        keterangan: "",
        tanggal_mulai: "",
        tanggal_selesai: "",
      },
      loadingTable: true,
      table_data: [],
      columns: columns,
      // parsing data ke table data
      tableData: {
        page: 1,
        length: 10,
        search: "",
        column: 0,
        dir: "asc",
        filter_status: "ENABLE", // untuk filtering data
      },
      // config untuk paginasi
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },
      isPreview: false,
      editorConfig: {
        // TinyMCE configuration options
        menubar: false, // Show the menu bar
        height: 280,
        resize: false,
        toolbar:
          "undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview media | forecolor backcolor emoticons",
        branding: false, // Hide the TinyMCE branding
      },
      contentTinymce: "",
    };
  },
  watch: {
    refreshTable(newVal) {
      if (newVal) {
        // Jalankan logika yang diperlukan ketika modal ditampilkan
        this.getDataTable();
        this.isPreview = false;
      }
    },
  },
  async mounted() {
    await this.getDataTable();
  },
  methods: {
    onMounted() {
      this.getDataTable();
    },
    getDataTable(
      url = process.env.VUE_APP_BACKEND_URL_VERSION +
        "peserta/jadwal-peserta/list-jawaban-esai?"
    ) {
      console.log(this.idsoal);
      let self = this;
      self.loadingTable = true;

      var config = {
        method: "get",
        url: url,
        params: {
          id_soal: this.idsoal,
          id_jadwal: this.idJadwal,
          id_jadwal_peserta: this.idJadwalPeserta,
          page: this.tableData.page,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token_peserta,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data;
          if (response_data.meta.code == 200) {
            self.table_data = response_data_fix.data;
            console.log(self.table_data);
            self.configPagination(response_data_fix);
            self.currentTablePage = response_data_fix.current_page;
            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    restoreData(data) {
      let self = this; // Variabel self untuk menyimpan referensi ke objek komponen
      var config_store_data = {
        method: "post",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "peserta/jadwal-peserta/rollback-jawaban",
        data: {
          idjawaban_esai: data.idjawaban_peserta_esai,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token_peserta,
        },
      };
      console.log(this.contentTinymce);
      axios(config_store_data)
        .then(function (response) {
          // Memuat kembali data setelah berhasil memulihkan data
          self.getDataTable();
          console.log("Jawaban berhasil disimpan:", response.data);
          Swal.fire({
            icon: "success",
            title: "Jawaban berhasil disimpan!",
            text: "Jawaban berhasil disimpan di server.",
            timer: 2000,
            timerProgressBar: true,
          });
        })
        .catch(function (error) {
          // Handle error jika terjadi
          console.error("Error saat menyimpan jawaban:", error);
          self.axiosCatchError = error.response.data.data; // Tangani error dan simpan pesan ke objek komponen Vue
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Terjadi kesalahan saat menyimpan jawaban.",
          });
        });
    },
    configPagination(data) {
      let self = this;
      self.pagination.lastPage = data.last_page;
      self.pagination.currentPage = data.current_page;
      self.pagination.total = data.total;
      self.pagination.lastPageUrl = data.last_page_url;
      self.pagination.nextPageUrl = data.next_page_url;
      self.pagination.prevPageUrl = data.prev_page_url;
      self.pagination.from = data.from;
      self.pagination.to = data.to;
      self.pagination.links = data.links;
      self.pagination.page = data.current_page;
    },
    sortBy(key) {
      let self = this;
      self.sortKey = key;
      self.sortOrders[key] = self.sortOrders[key] * -1;
      self.tableData.column = self.getIndex(self.columns, "name", key);
      self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
      self.getDataTable();
    },
    toPage(url) {
      let link = new URLSearchParams(url);
      this.tableData.page = link.get("page");
      // this.tableFilter.per_page = link.get('per_page');
      this.getDataTable();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },
    lihatJawaban(data) {
      // Menggunakan data yang diterima dari modal
      // this.content = data.jawaban;
      // this.getDataTable();
      // this.modalHistoryJawaban = false;
      // this.$emit("jawaban-selected", data);
      // // Lakukan sesuatu dengan data yang diterima dari modal
      // console.log("Data yang diterima dari modal:", data);
      console.log(data);
      this.contentTinymce = data.jawaban;
      this.isPreview = true;
    },
  },
};
</script>
<style scoped>
/* Add your custom styles here if needed */
</style>

<template>
  <div class="container">
    <div id="list-jawaban" v-if="!isPreview">
      <div class="col-md-12 mb-2">
        <div class="row">
          <div class="col-md-4">
            <table>
              <tr>
                <td>Show</td>
                <td>
                  <select
                    class="form-control form-control-sm"
                    id="showpaginatetable"
                    style="width: 100%"
                    v-model="tableData.length"
                    @change="getDataTable()"
                  >
                    <option value="10" selected>10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                    <option value="500">500</option>
                  </select>
                </td>
                <td>Entries</td>
              </tr>
            </table>
          </div>
        </div>
        <datatable
          :columns="columns"
          :sortKey="sortKey"
          :sortOrders="sortOrders"
          @sort="sortBy"
        >
          <tbody>
            <tr v-if="loadingTable">
              <td colspan="6">
                <i class="fas fa-spinner fa-spin"></i> Loading...
              </td>
            </tr>
            <tr v-else-if="table_data == ''">
              <td class="text-center" colspan="6">Data Tidak Tersedia</td>
            </tr>
            <tr
              v-else
              v-for="(row_data, key_data) in table_data"
              :key="key_data"
            >
              <td class="text-center">{{ pagination.from + key_data }}.</td>
              <td class="text-center">
                {{
                  row_data.created_at
                    ? fullDateTimeFormat(row_data.created_at)
                    : "-"
                }}
              </td>
              <td>
                <div class="d-flex">
                  <button
                    type="button"
                    style="width: 150px; margin-right: 10px"
                    class="btn btn-secondary btn-sm"
                    @click="lihatJawaban(row_data)"
                  >
                    Lihat Jawaban
                  </button>
                  <button
                    type="button"
                    style="width: 150px"
                    class="btn btn-warning btn-sm"
                    @click="restoreData(row_data)"
                  >
                    Restore
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </datatable>
      </div>
      <pagination :pagination="pagination" @to-page="toPage"></pagination>
    </div>
    <div id="preview-jawaban" v-if="isPreview">
      <div class="row mb-2">
        <div class="col">
          <button class="btn btn-sm btn-danger" @click="(isPreview = false)">
            <i class="fa fa-arrow-left me-1"> </i> Kembali
          </button>
        </div>
      </div>
      <div class="row">
        <Editor
          api-key="wdlv4g0lb9v38bllo9fgy6yxvuo4wnhauak1zpfjfsub4qbl"
          :init="editorConfig"
          v-model="contentTinymce"
          ref="editor"
        ></Editor>
      </div>
    </div>
  </div>
</template>
