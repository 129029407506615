<script>
import appConfig from '@/app.config';
import { integer } from '@vuelidate/validators';

export default {
  page: {
    title: 'Dashboard',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    // Layout,
    // PageHeader,
    // HeaderAsesmenHC,
  },
  props: {
    list: [],
    currentPage: integer,
    nextSoal: Function,
  },
  data() {
    return {
      title: 'Dashboard',
      items: [
        {
          text: 'Dashboards',
          href: '/',
        },
        {
          text: 'Default',
          active: true,
        },
      ],
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      user_name: localStorage.session_name,
      pathPhoto: localStorage.session_path_photo,
      pathPhotoFull:
        process.env.VUE_APP_BACKEND_URL + localStorage.session_path_photo,
      idJawabanPetunjuk: 0,
      link: process.env.VUE_APP_BACKEND_URL,
    };
  },
  methods: {
    jawabanPetunjuk(id) {
      this.idJawabanPetunjuk = id;
    },
  },
};
</script>

<template>
  <div
    class="font-size-14"
    style="border-bottom-right-radius: 5px; border-bottom-left-radius: 5px"
  >
    <p>
      Silahkan pilih salah satu jawaban dengan klik pada pilihan jawaban yang
      tersedia.
    </p>
    <p>Klik "preview" untuk menampilkan gambar</p>
    <div><strong>Contoh Soal</strong></div>
    <div class="bg-light">
      <p class="p-2 mb-0">
        {{ list.soal_text }}
      </p>
    </div>
    <img
      v-if="list.soal_image"
      :src="link + list.soal_image"
      style="max-width: 70vw"
      class="mx-auto mb-2"
    />
    <div class="soal mt-3 px-3">1. {{ list.pertanyaan }}</div>
    <div class="pilihan-ganda row mx-4">
      <div class="d-flex">
        <button
          class="mt-3 btn btn-block flex-grow-1"
          style="width: 200px"
          v-for="jawaban in list.jawaban"
          :key="jawaban.idjawaban"
          type="button"
          :class="[
            'btn',
            'mx-2',
            idJawabanPetunjuk === jawaban.idjawaban
              ? 'btn-primary'
              : 'btn-secondary',
          ]"
          @click="jawabanPetunjuk(jawaban.idjawaban)"
        >
          {{ jawaban.jawaban }}
        </button>
      </div>
    </div>
  </div>
</template>
<style scoped>
.border-none {
  border-style: hidden;
}
</style>
