<script>
import appConfig from '@/app.config';
import Swal from 'sweetalert2';
import { ref } from 'vue';

export default {
  page: {
    title: 'Dashboard',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {},
  props: {
    list: [],
    selectAnswer: Function,
    nextSoal: Function,
  },
  setup(props) {
    const allAnswers = props.list.reduce((accumulator, currentValue) => {
      return [...accumulator, ...currentValue.jawaban];
    }, []);
    console.log(allAnswers);

    const items = ref(allAnswers.map((item) => ({ ...item, list: 1 })));

    const getList = (list) => {
      return items.value.filter((item) => item.list == list);
    };

    const startDrag = (event, item) => {
      console.log(item);
      event.dataTransfer.dropEffect = 'move';
      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.setData('itemID', item.idjawaban);
    };

    const onDrop = (event, list) => {
      const itemID = event.dataTransfer.getData('itemID');
      const item = items.value.find((item) => item.idjawaban == itemID);
      if (list !== 1 && getList(list).length > 0) {
        console.log(`Hanya satu jawaban yang diizinkan untuk masuk!`);
        return;
      }
      console.log(list);
      console.log(props.list[0]);
      if (typeof props.selectAnswer === 'function') {
        props.selectAnswer(props.list[0], item);
      }
      item.list = list;
    };

    const onDragLeave = () => {
      // Lakukan apa pun yang diperlukan ketika data di-drag keluar tabel
      console.log('Data di-drag keluar tabel');
    };

    return {
      getList,
      onDrop,
      startDrag,
      onDragLeave,
    };
  },
  data() {
    return {
      title: 'Dashboard',
      items: [
        {
          text: 'Dashboards',
          href: '/',
        },
        {
          text: 'Default',
          active: true,
        },
      ],
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      user_name: localStorage.session_name,
      pathPhoto: localStorage.session_path_photo,
      pathPhotoFull:
        process.env.VUE_APP_BACKEND_URL + localStorage.session_path_photo,
      modalProgress: false,
      idJawabanPetunjuk: 0,
      link: process.env.VUE_APP_BACKEND_URL,
    };
  },
  mounted() {
    console.log('ini log item');
    console.log(this.list);
  },
  methods: {
    jawabanPetunjuk(id) {
      this.idJawabanPetunjuk = id;
    },
    mengerjakan() {
      this.nextSoal(0);
    },
    selesai() {
      Swal.fire({
        icon: 'warning',
        title: 'Konfirmasi!',
        text: 'Apakah Anda Yakin?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Ya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            icon: 'success',
            title: 'Selesai!',
            text: 'Jawaban berhasil di simpan',
            timer: 2000,
            timerProgressBar: true,
          });
          this.modalProgress = false;
        } else {
          this.modalProgress = false;
        }
      });
    },
  },
};
</script>
<template>
  <div
    class="font-size-14"
    style="border-bottom-right-radius: 5px; border-bottom-left-radius: 5px"
  >
    <p>
      Silahkan tekan dan geser jawaban disebelah kanan agar dapat masuk ke soal
      disebelah kiri
    </p>
    <p>Klik "preview" untuk menampilkan gambar</p>
    <hr />
    <div>
      <strong>Contoh Soal</strong>
      <img
        v-if="list[0].soal_image"
        :src="link + list[0].soal_image"
        style="max-width: 70vw"
        class="mx-auto mb-2"
      />
      <!-- <div class="card mb-2">
        <div class="card-body bg-light" style="border-radius: 5px">
          {{ list.soal_text }}
        </div>
      </div> -->
      <div class="col-xl-12">
        <div class="card border card-soal">
          <div class="card-body body-soal">
            <h5>Pertanyaan :</h5>
            {{ list[0].soal_text }}
          </div>
        </div>
      </div>
      <div class="row my-2">
        <div class="col-xl-8">
          <div class="card h-100">
            <div
              class="card-body border table-responsive"
              style="border-radius: 5px"
            >
              <table
                class="table align-middle table-nowrap table-striped table-bordered font-size-14"
              >
                <thead class="table-primary">
                  <tr>
                    <td width="5%" style="text-align: center">No</td>
                    <td width="50%">Soal</td>
                    <td width="35%">Jawab</td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="soal in list" :key="soal.idsoal">
                    <td width="5%" style="text-align: center">
                      1
                    </td>
                    <td width="50%">{{ soal.pertanyaan }}</td>
                    <td width="35%">
                      <div
                        class="drop-zone"
                        style="border-radius: 5px"
                        @drop="onDrop($event, 2)"
                        @dragenter.prevent
                        @dragover.prevent
                        @dragleave="onDragLeave"
                      >
                        <div
                          v-for="item in getList(2)"
                          :key="item.idjawaban"
                          style="border-radius: 5px"
                          class="drag-el"
                          draggable="true"
                          @dragstart="startDrag($event, item)"
                        >
                          {{ item.jawaban }}
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="offset-xl-1 col-xl-3">
          <div class="card h-100">
            <div class="card-body border" style="border-radius: 5px">
              <div
                class="drop-zone h-100"
                style="border-radius: 5px"
                @drop="onDrop($event, 1)"
                @dragenter.prevent
                @dragover.prevent
              >
                <div
                  v-for="item in getList(1)"
                  :key="item.idjawaban"
                  style="border-radius: 5px"
                  class="drag-el m-2"
                  draggable="true"
                  @dragstart="startDrag($event, item)"
                >
                  {{ item.jawaban }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.drop-zone {
  background-color: #ecf0f1;
  padding: 5px;
  min-height: 30px;
  text-align: center;
}
.drag-el {
  background-color: #556ee6;
  color: white;
  padding: 5px;
}

.card-soal {
  background-color: #ecf0f1;
}
.body-soal {
  border-radius: 5px;
  padding: 5px;
  margin: 10px;
}
</style>
