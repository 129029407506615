<script>
// import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
// import PageHeader from "@/components/page-header";
import Swal from "sweetalert2";
import axios from "axios";
import HeaderAsesmenHC from "./header_asesmen-hc.vue";
import PilihanGandaComponent from "./pilihan-ganda/soal_pilihan_ganda.vue";
import PilihanGandaNarasiComponent from "./pg-dengan-narasi/soal-1_pg_dengan_narasi.vue";
import MenjodohkanComponent from "./menjodohkan/soal_menjodohkan.vue";
import BenarSalahComponent from "./salah-benar/soal_salah_benar.vue";
import PetunjukPilihanGanda from "./pilihan-ganda/petunjuk_pilihan_ganda.vue";
import PetunjukPilihanGandaNarasi from "./pg-dengan-narasi/petunjuk_pg_dengan_narasi.vue";
import PetunjukMenjodohkan from "./menjodohkan/petunjuk_menjodohkan.vue";
import PetunjukSalahBenar from "./salah-benar/petunjuk_salah_benar.vue";
import InfoPengerjaanPilihanGanda from "./pilihan-ganda/info_pengerjaan_pilihan_ganda.vue";
import InfoPengerjaanSalahBenar from "./salah-benar/info_pengerjaan_salah_benar.vue";
import InfoPengerjaanMenjodohkan from "./menjodohkan/info_pengerjaan_menjodohkan.vue";
import InfoPengerjaanText from "./text/info_pengerjaan_text.vue";
import TextComponent from "./text/soal_text.vue";
import PetunjukText from "./text/petunjuk_text.vue";
import FooterPeserta from "../../../components/footer-peserta.vue";
import CryptoJS from "crypto-js";
import router from "@/router";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard Soal",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    // Layout,
    // PageHeader,
    HeaderAsesmenHC,
    PilihanGandaComponent,
    PilihanGandaNarasiComponent,
    BenarSalahComponent,
    MenjodohkanComponent,
    TextComponent,
    PetunjukPilihanGanda,
    PetunjukPilihanGandaNarasi,
    PetunjukMenjodohkan,
    PetunjukSalahBenar,
    PetunjukText,
    InfoPengerjaanPilihanGanda,
    InfoPengerjaanSalahBenar,
    InfoPengerjaanMenjodohkan,
    InfoPengerjaanText,
    FooterPeserta,
  },
  data() {
    return {
      title: "Dashboard Soal",
      items: [
        {
          text: "Dashboards_soal",
          href: "/",
        },
        {
          text: "Default",
          active: true,
        },
      ],
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      user_name: localStorage.session_name,
      pathPhoto: localStorage.session_path_photo,
      pathPhotoFull:
        process.env.VUE_APP_BACKEND_URL + localStorage.session_path_photo,
      modalProgress: false,
      modalInfo: false,
      infoTipe: "Pilihan Ganda",
      dataInfo: {},
      dataInfoPilihanGanda: null,
      dataInfoText: null,
      dataInfoBenarSalah: null,
      dataInfoMenjodohkan: null,
      listItem: [],
      soal: [],
      tipe: "",
      currentPage: 1,
      perPage: 1,
      countdown: 0,
      last_page: 0,
      numPage: 0,
      paramSoal: null,
      id_kompetensi: null,
      id_jadwal: null,
      id_jadwal_peserta: null,
      id_jadwal_batch: null,
      id_status: null,
      index_soal: 0,
      is_instruksi: 0,
      nama_kompetensi: "-",
      myInstruksi: 0,
      nomor_urut: 1,
      status_pengerjaan: 0,
      nip_peserta: localStorage.nip_peserta,
      previewImage: false,
      urlSrc: "",
    };
  },
  computed: {
    formattedTime() {
      const minutes = Math.floor(this.countdown / 60);
      const seconds = this.countdown % 60;
      return `${this.formatTime(minutes)}:${this.formatTime(seconds)}`;
    },

    groupedItems() {
      const grouped = [];
      let currentIndex = 0;
      let reset = 0;
      // Distribute items in each row (6, 4, 6, 4, ...)
      for (let rowIndex = 0; currentIndex < this.listItem.length; rowIndex++) {
        const rowLength = 6;
        const rowItems = this.listItem.slice(
          currentIndex,
          currentIndex + rowLength
        );

        // Iterate through rowItems to add index or null based on is_instruksi
        let instruksi = 0;
        console.log("rowItems: ", rowItems);
        const updatedRowItems = rowItems.map((item) => {
          if (item.is_instruksi === 1) {
            instruksi = instruksi + 1;
            reset = 0;
            return { ...item, nomor_urut: null };
          } else {
            reset++;
            return {
              ...item,
              nomor_urut: reset,
            };
          }
        });

        grouped.push(updatedRowItems);
        currentIndex += rowLength;
      }
      console.log("grouped: ", grouped);
      console.log("listItem: ", this.listItem);

      return grouped;
    },
    groupedItems2() {
      let grouped = [];
      let index = -1;
      let reset = 0;

      this.listItem.forEach((e, i) => {
        if (e.is_instruksi === 1) {
          index++;
          reset = 0;
          e.soal = [];
          grouped.push(e);
        } else {
          reset++;
          e.nomor_urut = reset;
          this.listItem[i].nomor_urut = reset;
          grouped[index].soal.push(e);
        }
      });
      console.log("grouped2", grouped);

      return grouped;
    },
    groupedItems3() {
      let result = [];
      let nomor_urut = 0;
      this.listItem.forEach((e) => {
        if (e.is_instruksi === 1) {
          nomor_urut = 0;
        } else {
          nomor_urut++;
          result[e.idsoal] = {
            nomor_urut: nomor_urut,
          };
        }
      });

      return result;
    },
  },

  mounted() {
    let self = this;
    self.cekParamSoal();

    document.addEventListener("keydown", (event) => {
      if (event.ctrlKey) {
        event.preventDefault();
        alert("Ctrl tidak diperbolehkan di halaman ini.");
      }
      if (event.altKey) {
        event.preventDefault();
        alert("Alt tidak diperbolehkan di halaman ini.");
      }
      if (event.key === "PrintScreen" || event.key === "PrtScr") {
        event.preventDefault();
        alert("Print Screen tidak diperbolehkan di halaman ini.");
      }
    });

    document.addEventListener("keyup", (event) => {
      if (event.key === "PrintScreen" || event.key === "PrtScr") {
        event.preventDefault();
        alert("Print Screen tidak diperbolehkan di halaman ini.");
      }
    });

    document.addEventListener("contextmenu", (event) => {
      event.preventDefault();
      alert("Klik kanan tidak diperbolehkan di halaman ini.");
    });
  },

  methods: {
    async cekParamSoal() {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      this.paramSoal = this.$route.query.soal;
      // console.log(this.paramSoal);
      if (
        this.paramSoal === null ||
        this.paramSoal === "" ||
        !this.$route.query.soal
      ) {
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Soal tidak ditemukan, silahkan menuju ke halaman dashboard",
          showCancelButton: false,
          confirmButtonText: "Kembali ke Dashboard",
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push({
              name: "dashboard-assesmen-hc",
            });
          }
        });
      } else {
        let get_param = CryptoJS.AES.decrypt(
          this.paramSoal,
          "4lfahumaNeEd5ME!"
        ).toString(CryptoJS.enc.Utf8);
        console.log("param soal: ", get_param);
        await this.getDataFromParam(get_param);
        await this.getListSoal();
        await this.getSoal();
        this.startTimer();
        Swal.close();
      }
    },

    setCountdown(param) {
      let splittedArray = param.split("_");
      const countDownBatch = splittedArray[9];
      if (countDownBatch >= 0 && countDownBatch <= this.countdown) {
        this.countdown = countDownBatch;
      }
    },

    getDataFromParam(param) {
      let splittedArray = param.split("_");

      this.id_kompetensi = splittedArray[0];
      this.id_jadwal_peserta = splittedArray[1];
      this.id_jadwal = splittedArray[2];
      this.id_jadwal_batch = splittedArray[3];
      this.timer = splittedArray[4] * 60;
      this.nama_kompetensi = splittedArray[5];
      this.id_kompetensi = splittedArray[6];
      this.level = splittedArray[7];
      this.id_status = splittedArray[8];
      this.countdown = splittedArray[4] * 60;
      // const countDownBatch = splittedArray[9];
      // if (countDownBatch >= 0 && countDownBatch <= this.countdown) {
      //   this.countdown = countDownBatch;
      // }
    },

    selesai() {
      // let self = this; // Store reference to Vue instance

      Swal.fire({
        icon: "warning",
        title: "Semua soal selesai dikerjakan!",
        text: "Apakah anda yakin untuk mengakhiri ujian?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Loading...",
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
          });

          var config_store_data = {
            method: "post",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "peserta/jadwal-peserta/submit-jawaban",
            data: {
              id_kompetensi: this.id_kompetensi,
              id_jadwal_peserta: this.id_jadwal_peserta,
              id_jadwal: this.id_jadwal,
              id_status: this.id_status,
              level: this.level,
              is_simulasi: localStorage.getItem("is_simulasi"),
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token_peserta,
            },
          };

          await axios(config_store_data)
            .then(function (response) {
              Swal.close();
              let response_data = response.data.data;
              if (response_data.meta.code == 200) {
                Swal.fire({
                  icon: "success",
                  title: "Selesai!",
                  text: "Jawaban berhasil di simpan",
                  timer: 2000,
                  timerProgressBar: true,
                });
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: response_data.data.message,
                });
              }
            })
            .catch(function (error) {
              console.log(error);
              // self.axiosCatchError = error.response.data.data;
            });
          let cek = await this.cekJawaban();
          if (cek == false) {
            Swal.fire({
              icon: "warning",
              title: "Terjadi Kesalahan",
              text: "Silahkan klik tombol selesai kembali",
            });
            return;
          }
          let param_text =
            this.id_jadwal +
            "_" +
            this.id_jadwal_peserta +
            "_" +
            this.id_jadwal_batch +
            "_" +
            this.id_kompetensi +
            "_" +
            this.level +
            "_" +
            this.id_status;
          let my_param = CryptoJS.AES.encrypt(
            param_text,
            "4lfahumaNeEd5ME!"
          ).toString();
          router.push({
            name: "survey",
            query: { soal: my_param },
          });
          this.modalProgress = false;
        } else {
          this.modalProgress = false;
        }
      });
    },

    cekSelesai() {
      // Show loading message
      Swal.fire({
        title: "Mohon tunggu sebentar",
        text: "Sedang mengecek jawaban anda !!",
        allowOutsideClick: false,
        allowEscapeKey: false,
        icon: "info",
        showConfirmButton: false,
      });

      let jawabanBelumDijawab = false;

      this.groupedItems.forEach((row) => {
        row.forEach((element) => {
          console.log(element);
          if (
            element.is_instruksi !== 1 &&
            element.tipe !== "Text" &&
            (!element.jawaban_peserta || element.jawaban_peserta === null)
          ) {
            // Found an answered question
            jawabanBelumDijawab = true;
            return;
          }
        });
        if (jawabanBelumDijawab) {
          // Break the outer loop if an unanswered question is found
          return;
        }
      });

      if (jawabanBelumDijawab) {
        // If there are unanswered questions, show the alert
        Swal.fire({
          title: "Ada jawaban yang masih belum dijawab",
          icon: "warning",
          confirmButtonText: "OK",
        }).then(() => {
          // Set modalProgress to true when "OK" is clicked
          this.modalProgress = true;
        });
      } else {
        // If all questions are answered, call this.selesai
        this.selesai();
      }
    },

    async getListSoal() {
      let self = this;
      try {
        let response = await axios.get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "peserta/jadwal-peserta/list-soal-peserta",
          {
            params: {
              id_kompetensi: this.id_kompetensi,
              idjadwal: this.id_jadwal,
              idjadwal_peserta: this.id_jadwal_peserta,
              level: this.level,
              is_simulasi: localStorage.getItem("is_simulasi"),
              // tipe: "Pilihan Ganda",
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token_peserta,
            },
          }
        );

        let response_data = response.data;
        self.last_page = response_data.data.length;
        let data = response.data.data;

        if (response_data.meta.code == 200) {
          self.listItem = data;
          self.getCurrentIndex();
          // self.groupedItems();
          // await self.groupedItems2();
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response_data.data.message,
          });
        }
      } catch (errors) {
        console.log(errors);
      }
    },

    getCurrentIndex() {
      /**
       * todo: tampilkan soal berikutnya dari soal yang sudah terjawab
       * todo: jika soal terakhir yang terjawab, maka tampilkan soal terakhir tersebut
       * todo: countdown ambil dari time_remaining dari jawaban terakhir
       */
      this.listItem.forEach((element, index) => {
        if (element.is_instruksi == 0) {
          if (element.jawaban_peserta != null) {
            this.countdown = element.jawaban_peserta.time_remaining * 60;
            if (this.index_soal != (this.listItem.length - 1)) {
              this.index_soal = index +1;
            }
          }
        }
      });
    },

    async getListSoalRedis() {
      console.log("callredis");
      let self = this;
      try {
        let response = await axios.get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "peserta/jadwal-peserta/list-soal-peserta-redis",
          {
            params: {
              id_kompetensi: this.id_kompetensi,
              idjadwal: this.id_jadwal,
              idjadwal_peserta: this.id_jadwal_peserta,
              level: this.level,
              is_simulasi: localStorage.getItem("is_simulasi"),
              // tipe: "Pilihan Ganda",
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token_peserta,
            },
          }
        );

        console.log(response);
        let response_data = response.data;
        self.last_page = response_data.data.length;
        let data = response.data.data;

        if (response_data.meta.code == 200) {
          self.listItem = data;
          // console.log('huaaa');
          // self.groupedItems();
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response_data.data.message,
          });
        }
      } catch (errors) {
        console.log(errors);
      }
    },

    async getSoal() {
      let self = this;

      // Show loading indicator
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      try {
        let response = await axios.get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "peserta/jadwal-peserta/soal-peserta",
          {
            params: {
              // id_kompetensi: this.id_kompetensi,
              id_soal: this.listItem[this.index_soal].idsoal,
              tipe: this.listItem[this.index_soal].tipe,
              id_jadwal_peserta: this.id_jadwal_peserta,
              is_simulasi: localStorage.getItem("is_simulasi"),
              // per_page: self.perPage,
              // page: self.currentPage,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token_peserta,
            },
          }
        );

        let response_data = response.data;
        let response_page = response.data.data;
        let data = response.data.data.data;
        // Close loading indicator
        Swal.close();

        if (response_data.meta.code == 200) {
          self.soal = data;
          self.perPage = response_page.per_page;
          self.currentPage = response_page.current_page;
          self.is_instruksi = this.listItem[this.index_soal].is_instruksi;
          self.tipe = self.soal[0]?.tipe;
          console.log(self.is_instruksi);
          let urutanSoal = await self.groupedItems3();
          this.nomor_urut = urutanSoal[self.soal[0].idsoal];
          // if (self.currentPage == 1 || self.countdown == 0) {
          //   self.countdown = self.timer;
          //   self.startTimer();
          // } else {
          //   self.countdown = self.timer;
          // }
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response_data.data.message,
          });
        }
      } catch (errors) {
        // Close loading indicator in case of an error
        Swal.close();
        // console.log(errors);
      }
    },

    simpanJawaban(item, selectedJawaban) {
      let self = this;
      const minutes = Math.floor(this.countdown / 60);
      var config_store_data = {
        method: "post",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "peserta/jadwal-peserta/simpan-jawaban",
        data: {
          id_kompetensi: this.id_kompetensi,
          id_jawaban: selectedJawaban.idjawaban,
          id_jadwal_peserta: this.id_jadwal_peserta,
          id_soal: item.idsoal,
          id_jadwal: this.id_jadwal,
          skor: selectedJawaban.skor,
          id_jadwal_batch: this.id_jadwal_batch,
          time_remaining: minutes,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token_peserta,
        },
      };
      axios(config_store_data)
        .then(function (response) {
          console.log("respon jawaban" + response);
          // if (self.last_page - 1 !== self.index_soal) {
          //   self.index_soal++;
          // }
          // Using self instead of this
          self.getListSoalRedis(); // Using self instead of this
        })
        .catch(function (error) {
          self.axiosCatchError = error;
        });
    },

    async cekJawaban() {
      const data = await axios({
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "peserta/jadwal-peserta/cek-jawaban",
        data: {
          id_kompetensi: this.id_kompetensi,
          id_jadwal_peserta: this.id_jadwal_peserta,
          id_jadwal: this.id_jadwal,
          id_status: this.id_status,
          level: this.level,
          is_simulasi: localStorage.getItem("is_simulasi"),
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token_peserta,
        },
      })
        .then(() => {
          return true;
        })
        .catch(() => {
          return false;
        });
      return data;
    },

    prevSoal() {
      let self = this;
      self.currentPage = self.currentPage - 1;
      self.index_soal = self.index_soal - 1;
      self.getSoal();
    },

    backSoal() {
      console.log("ini soal");
      console.log(this.soal);
      let self = this;
      console.log("currentPage", self.currentPage);
      console.log("index_soal", self.index_soal);
      self.currentPage = self.currentPage - 1;
      self.index_soal = self.index_soal - 1;
      self.nomor_urut -= 1;

      self.getSoal();
    },

    nextSoal(number) {
      if (number != 0) {
        Swal.fire({
          title: "Apakah Anda telah yakin dengan jawaban yang dipilih?",
          text: "Jika iya, halaman akan beralih ke soal berikutnya.",
          icon: "warning",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ya",
        }).then((result) => {
          // <--
          if (result.value) {
            // <-- if confirmed
            Swal.fire({
              title: "Loading...",
              allowOutsideClick: false,
              showConfirmButton: false,
              onBeforeOpen: () => {
                Swal.showLoading();
              },
            });

            let jawabanBelumDijawab = false;

            let dataSoal = this.listItem[this.index_soal];
            if (
              dataSoal.is_instruksi !== 1 &&
              (!dataSoal.jawaban_peserta || dataSoal.jawaban_peserta === null)
            ) {
              jawabanBelumDijawab = true;
            }

            console.log(jawabanBelumDijawab);
            console.log(dataSoal.jawaban_peserta);
            console.log(this.listItem);
            if (jawabanBelumDijawab) {
              // If there are unanswered questions, show the alert
              Swal.close();
              Swal.fire({
                title: "Belum ada Jawaban",
                text: "Mohon isi terlebih dahulu jawaban, sebelum melanjutkan",
                icon: "warning",
                confirmButtonText: "OK",
              });
            } else {
              let self = this;
              // self.stopTimer;
              self.currentPage = self.currentPage + 1;
              self.index_soal = self.index_soal + 1;
              number == 0 ? (self.nomor_urut = 1) : (self.nomor_urut += number);
              // self.nomor_urut += number;
              console.log("cureeent", self.index_soal);
              console.log(self.last_page);
              // console.log('last', self.last_page);
              self.getSoal();
            }
          }
        });
      } else {
        Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });

        let jawabanBelumDijawab = false;

        let dataSoal = this.listItem[this.index_soal];
        if (
          dataSoal.is_instruksi !== 1 &&
          (!dataSoal.jawaban_peserta || dataSoal.jawaban_peserta === null)
        ) {
          jawabanBelumDijawab = true;
        }

        console.log(jawabanBelumDijawab);
        console.log(dataSoal.jawaban_peserta);
        console.log(this.listItem);
        if (jawabanBelumDijawab) {
          // If there are unanswered questions, show the alert
          Swal.close();
          Swal.fire({
            title: "Belum ada Jawaban",
            text: "Mohon isi terlebih dahulu jawaban, sebelum melanjutkan",
            icon: "warning",
            confirmButtonText: "OK",
          });
        } else {
          let self = this;
          // self.stopTimer;
          self.currentPage = self.currentPage + 1;
          self.index_soal = self.index_soal + 1;
          number == 0 ? (self.nomor_urut = 1) : (self.nomor_urut += number);
          // self.nomor_urut += number;
          console.log("cureeent", self.index_soal);
          console.log(self.last_page);
          // console.log('last', self.last_page);
          self.getSoal();
        }
      }

      // Show loading message
    },

    async toSoal(idsoal, tipe, nomor_urut) {
      if (this.nomor_urut > nomor_urut) {
        this.index_soal = this.index_soal - (this.nomor_urut - nomor_urut);
      } else {
        this.index_soal = this.index_soal + (nomor_urut - this.nomor_urut);
      }

      // Show loading indicator
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      try {
        let response = await axios.get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "peserta/jadwal-peserta/soal-peserta",
          {
            params: {
              id_soal: idsoal,
              tipe: tipe,
              id_jadwal_peserta: this.id_jadwal_peserta,
              is_simulasi: localStorage.getItem("is_simulasi"),
              // per_page: self.perPage,
              // page: self.currentPage,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token_peserta,
            },
          }
        );

        let response_data = response.data;
        let response_page = response.data.data;
        let data = response.data.data.data;
        // Close loading indicator
        Swal.close();

        if (response_data.meta.code == 200) {
          this.soal = data;
          this.perPage = response_page.per_page;
          this.currentPage = response_page.current_page;
          this.is_instruksi = this.listItem[this.index_soal].is_instruksi;
          this.nomor_urut = nomor_urut;
          this.tipe = self.soal[0]?.tipe;
          // if (self.currentPage == 1 || self.countdown == 0) {
          //   self.countdown = self.timer;
          //   self.startTimer();
          // } else {
          //   self.countdown = self.timer;
          // }
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response_data.data.message,
          });
        }
      } catch (errors) {
        // Close loading indicator in case of an error
        Swal.close();
        // console.log(errors);
      }
    },

    jumPage(indexSoal, nomor_urut) {
      this.status_pengerjaan = 1;
      let self = this;
      self.index_soal = indexSoal;
      // console.log('indexsoal', indexSoal);
      this.modalProgress = false;
      this.nomor_urut = nomor_urut;
      self.getSoal();
    },

    selectAnswer(item, selectedJawaban) {
      // console.log(selectedJawaban);
      let self = this;

      self.simpanJawaban(item, selectedJawaban);
      console.log(
        "Before:",
        item.jawaban.map((j) => j.selected)
      ); // Debugging log
      // Reset selected state for all jawabans in the current item
      item.jawaban.forEach((jawaban) => {
        jawaban.selected = false;
      });

      // Set selected state for the clicked jawaban
      selectedJawaban.selected = true;

      console.log(
        "After:",
        item.jawaban.map((j) => j.selected)
      ); // Debugging log
      // self.getListSoal();
    },

    formatTime(time) {
      return time < 10 ? `0${time}` : time;
    },

    startTimer() {
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(this.timer);
          this.waktuHabis();
          // this.selesai();
          // if (this.index_soal + 1 === this.last_page) {
          //   this.selesai();
          // } else {
          //   this.nextSoal();
          // }
        }
      }, 1000); // Update every 1 second
    },

    waktuHabis() {
      Swal.fire({
        icon: "warning",
        title: "Waktu pengerjaan soal telah habis!",
        text: "Anda akan langsung diarahkan ke halaman berikutnya.",
        allowEscapeKey: false,
        allowOutsideClick: false,
      }).then(async () => {
        Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });

        var config_store_data = {
          method: "post",
          url:
            process.env.VUE_APP_BACKEND_URL_VERSION +
            "peserta/jadwal-peserta/submit-jawaban",
          data: {
            id_kompetensi: this.id_kompetensi,
            id_jadwal_peserta: this.id_jadwal_peserta,
            id_jadwal: this.id_jadwal,
            id_status: this.id_status,
            level: this.level,
            is_simulasi: localStorage.getItem("is_simulasi"),
          },
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.access_token_peserta,
          },
        };
        await axios(config_store_data)
          .then(function (response) {
            Swal.close();
            let response_data = response.data.data;
            if (response_data.meta.code == 200) {
              Swal.fire({
                icon: "success",
                title: "Selesai!",
                text: "Jawaban berhasil di simpan",
                timer: 2000,
                timerProgressBar: true,
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            // self.axiosCatchError = error.response.data.data;
          });

        let param_text =
          this.id_jadwal +
          "_" +
          this.id_jadwal_peserta +
          "_" +
          this.id_jadwal_batch +
          "_" +
          this.id_kompetensi +
          "_" +
          this.level +
          "_" +
          this.id_status;
        let my_param = CryptoJS.AES.encrypt(
          param_text,
          "4lfahumaNeEd5ME!"
        ).toString();
        router.push({
          name: "survey",
          query: { soal: my_param },
        });
      });
    },

    async infoPengerjaan(tipe) {
      switch (tipe) {
        case "Pilihan Ganda":
          this.dataInfoPilihanGanda =
            this.dataInfoPilihanGanda ?? (await this.getInfoPengerjaan(tipe));
          this.dataInfo = this.dataInfoPilihanGanda;
          break;
        case "Benar Salah":
          this.dataInfoBenarSalah =
            this.dataInfoBenarSalah ?? (await this.getInfoPengerjaan(tipe));
          this.dataInfo = this.dataInfoBenarSalah;
          break;
        case "Menjodohkan":
          this.dataInfoMenjodohkan =
            this.dataInfoMenjodohkan ?? (await this.getInfoPengerjaan(tipe));
          this.dataInfo = this.dataInfoMenjodohkan;
          break;
        case "Text":
          this.dataInfoText =
            this.dataInfoText ?? (await this.getInfoPengerjaan(tipe));
          this.dataInfo = this.dataInfoText;
          break;
        default:
          break;
      }
      console.log(this.dataInfo);
      this.infoTipe = tipe;
      this.modalInfo = true;
    },

    async getInfoPengerjaan(tipe) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      let soal = this.listItem.find(
        (e) => e.is_instruksi == 1 && e.tipe == tipe
      );
      let response = await axios.get(
        process.env.VUE_APP_BACKEND_URL_VERSION +
          "peserta/jadwal-peserta/soal-peserta",
        {
          params: {
            id_soal: soal.idsoal,
            tipe: soal.tipe,
            id_jadwal_peserta: this.id_jadwal_peserta,
            is_simulasi: localStorage.getItem("is_simulasi"),
          },
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.access_token_peserta,
          },
        }
      );
      let response_data = response.data;
      let data = response.data.data.data;
      let dataInfo = {};
      if (response_data.meta.code == 200) {
        dataInfo = tipe == "Menjodohkan" || tipe == "Text" ? data : data[0];
        console.log(this.dataInfo);
      }
      Swal.close();

      return dataInfo;
    },

    nomorSoal(idsoal) {
      let grouped = [];
      let reset = 0;

      this.listItem.forEach((e, i) => {
        if (e.is_instruksi === 1) {
          reset = 0;
          grouped[e.idsoal] = 1;
        } else {
          reset++;
          e.nomor_urut = reset;
          this.listItem[i].nomor_urut = reset;
          grouped[e.idsoal] = reset;
        }
      });

      return grouped[idsoal];
    },

    previewImageToggle(url) {
      this.urlSrc = url;
      this.previewImage = true;
    },
  },

  beforeUnmount() {
    clearInterval(this.timer); // Clear the interval when the component is destroyed
  },
};
</script>
<template>
  <b-modal
    v-model="previewImage"
    title="Preview Gambar"
    centered
    hide-footer
    size="xl"
  >
    <div class="text-center" style="position: relative; width: 100%">
      <img :src="urlSrc" class="image" />
    </div>
  </b-modal>
  <!-- <Layout>
    <PageHeader :title="title" :items="items" /> -->
  <div class="container-fluid">
    <!-- Watermark -->
    <div class="watermark watermark1">{{ nip_peserta }}</div>
    <div class="watermark watermark2">{{ nip_peserta }}</div>
    <div class="watermark watermark3">{{ nip_peserta }}</div>
    <div class="watermark watermark4">{{ nip_peserta }}</div>
    <div class="watermark watermark5">{{ nip_peserta }}</div>
    <div class="watermark watermark6">{{ nip_peserta }}</div>
    <!-- Watermark - end -->

    <HeaderAsesmenHC />
    <div class="card" style="border-radius: 10px; min-height: 80vh">
      <div
        class="card-header bg-primary text-white font-size-16"
        style="border-radius: 5px"
      >
        <div class="d-flex justify-content-between">
          <div class="">Uji Kompetensi : {{ nama_kompetensi }}</div>
          <div class="">Time Remaining: {{ formattedTime }}</div>
          <div class="">
            <button
              type="button"
              class="btn btn-light mx-2"
              v-if="is_instruksi !== 1"
              @click="infoPengerjaan(soal[0]?.tipe)"
            >
              Petunjuk
            </button>
            <button
              type="button"
              class="btn btn-info float-end"
              @click="modalProgress = true"
            >
              Lihat Progress
            </button>
            <b-modal
              v-model="modalInfo"
              size="xl"
              title="Petunjuk Pengerjaan"
              hide-footer
            >
              <InfoPengerjaanPilihanGanda
                v-if="infoTipe == 'Pilihan Ganda'"
                :list="dataInfo"
              />
              <InfoPengerjaanSalahBenar
                v-else-if="infoTipe === 'Benar Salah'"
                :list="dataInfo"
              />
              <InfoPengerjaanMenjodohkan
                v-else-if="infoTipe === 'Menjodohkan'"
                :list="dataInfo"
              />
              <InfoPengerjaanText
                v-else-if="infoTiper === 'Text'"
                :list="dataInfo"
                :idJadwal="id_jadwal"
                :idJadwalPeserta="id_jadwal_peserta"
              />
            </b-modal>
            <b-modal
              v-model="modalProgress"
              title="Progress Pengisian Soal"
              hide-footer
            >
              <div class="badge bg-danger form-control font-size-16 pt-4 pb-4">
                Masih ada soal yang belum jawab
              </div>
              <div
                v-for="(row, index) in groupedItems2"
                :key="index"
                class="row mt-2"
              >
                <div class="col-12">
                  <div class="d-grid gap-2">
                    <button
                      class="btn btn-secondary form-control font-size-16 pt-3 pb-3"
                    >
                      <i class="fa fa-info-circle"></i>
                      <span v-if="row.tipe == 'Text'"> Petunjuk Essai </span>
                      <span v-else> Petunjuk {{ row.tipe }} </span>
                    </button>
                  </div>
                </div>
                <div class="col-12 d-flex flex-wrap">
                  <div
                    class="child-number d-grid gap-2 pt-1"
                    v-for="(item, nomor) in row.soal"
                    :key="nomor"
                  >
                    <button
                      v-if="item.tipe == 'Menjodohkan'"
                      :class="{
                        'badge font-size-16 p-3': true,
                        'btn-success':
                          item.jawaban_peserta && item.jawaban_peserta !== null,
                        'btn-danger':
                          (!item.jawaban_peserta && item.is_instruksi !== 1) ||
                          (item.jawaban_peserta === null &&
                            item.is_instruksi !== 1),
                      }"
                      :disabled="
                        (!item.jawaban_peserta && item.is_instruksi !== 1) ||
                        (item.jawaban_peserta === null &&
                          item.is_instruksi !== 1) ||
                        soal[0]?.tipe != item.tipe
                      "
                      @click="toSoal(item.idsoal, item.tipe, item.nomor_urut)"
                    >
                      {{ nomor + 1 }}
                    </button>
                    <button
                      v-else
                      :class="{
                        'badge font-size-16 form-control p-3': true,
                        'bg-secondary': item.is_instruksi === 1,
                        'bg-success':
                          item.jawaban_peserta && item.jawaban_peserta !== null,
                        'bg-danger':
                          (!item.jawaban_peserta && item.is_instruksi !== 1) ||
                          (item.jawaban_peserta === null &&
                            item.is_instruksi !== 1),
                      }"
                    >
                      {{ nomor + 1 }}
                    </button>
                  </div>
                </div>
              </div>
              <!--              <div-->
              <!--                v-for="(row, rowIndex) in groupedItems"-->
              <!--                :key="rowIndex"-->
              <!--                class="row mt-4"-->
              <!--              >-->
              <!--                <div class="col-xl mb-2" v-for="item in row" :key="item.idsoal">-->
              <!--                  <button-->
              <!--                    v-if="item.is_instruksi != 1 && item.tipe == 'Menjodohkan'"-->
              <!--                    :class="{-->
              <!--                      'badge font-size-16 form-control p-3': true,-->
              <!--                      'btn-secondary': item.is_instruksi === 1,-->
              <!--                      'btn-success':-->
              <!--                        item.jawaban_peserta && item.jawaban_peserta !== null,-->
              <!--                      'btn-danger':-->
              <!--                        (!item.jawaban_peserta && item.is_instruksi !== 1) ||-->
              <!--                        (item.jawaban_peserta === null &&-->
              <!--                          item.is_instruksi !== 1),-->
              <!--                    }"-->
              <!--                    :disabled="-->
              <!--                      (!item.jawaban_peserta && item.is_instruksi !== 1) ||-->
              <!--                      (item.jawaban_peserta === null &&-->
              <!--                        item.is_instruksi !== 1) ||-->
              <!--                      soal[0]?.tipe != item.tipe-->
              <!--                    "-->
              <!--                    @click="toSoal(item.idsoal, item.tipe, item.nomor_urut)"-->
              <!--                  >-->
              <!--                    &lt;!&ndash; Check if is_instruksi is not 1, then display the number &ndash;&gt;-->
              <!--                    <span v-if="item.is_instruksi !== 1">-->
              <!--                      {{ item.nomor_urut }}-->
              <!--                    </span>-->
              <!--                    <span v-else>-->
              <!--                      <i class="fas fa-info-circle"></i>-->
              <!--                    </span>-->
              <!--                  </button>-->
              <!--                  <div-->
              <!--                    v-else-if="-->
              <!--                      item.is_instruksi != 1 && item.tipe != 'Menjodohkan'-->
              <!--                    "-->
              <!--                    :class="{-->
              <!--                      'badge font-size-16 form-control p-3': true,-->
              <!--                      'bg-secondary': item.is_instruksi === 1,-->
              <!--                      'bg-success':-->
              <!--                        item.jawaban_peserta && item.jawaban_peserta !== null,-->
              <!--                      'bg-danger':-->
              <!--                        (!item.jawaban_peserta && item.is_instruksi !== 1) ||-->
              <!--                        (item.jawaban_peserta === null &&-->
              <!--                          item.is_instruksi !== 1),-->
              <!--                    }"-->
              <!--                  >-->
              <!--                    &lt;!&ndash; Check if is_instruksi is not 1, then display the number &ndash;&gt;-->
              <!--                    <span v-if="item.is_instruksi !== 1">-->
              <!--                      {{ item.nomor_urut }}-->
              <!--                    </span>-->
              <!--                    <span v-else>-->
              <!--                      <i class="fas fa-info-circle"></i>-->
              <!--                    </span>-->
              <!--                  </div>-->
              <!--                  <div-->
              <!--                    v-else-->
              <!--                    :class="{-->
              <!--                      'badge font-size-16 form-control p-3': true,-->
              <!--                      'bg-secondary': item.is_instruksi === 1,-->
              <!--                    }"-->
              <!--                  >-->
              <!--                    &lt;!&ndash; Check if is_instruksi is not 1, then display the number &ndash;&gt;-->
              <!--                    <span v-if="item.is_instruksi !== 1">-->
              <!--                      {{ item.nomor_urut }}-->
              <!--                    </span>-->
              <!--                    <span v-else>-->
              <!--                      <i class="fas fa-info-circle"></i>-->
              <!--                    </span>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--                &lt;!&ndash; Fill empty positions if the row has fewer than 6 items &ndash;&gt;-->
              <!--                <div-->
              <!--                  v-for="emptyIndex in Math.max(0, 6 - row.length)"-->
              <!--                  :key="emptyIndex"-->
              <!--                  class="col-xl mb-2"-->
              <!--                ></div>-->
              <!--              </div>-->
            </b-modal>
          </div>
        </div>
      </div>
      <div class="card-body font-size-14">
        <div class="petunjuk" v-if="is_instruksi === 1">
          <div v-for="item in soal" :key="item.idsoal">
            <PetunjukPilihanGanda
              v-if="item.tipe === 'Pilihan Ganda' && item.soal_text === null"
              :list="item"
              :currentPage="currentPage"
              :nextSoal="nextSoal"
            />
            <PetunjukPilihanGandaNarasi
              v-if="item.tipe === 'Pilihan Ganda' && item.soal_text !== null"
              :list="item"
              :currentPage="currentPage"
              :nextSoal="nextSoal"
            />
            <PetunjukSalahBenar
              v-if="item.tipe === 'Benar Salah'"
              :list="item"
              :currentPage="currentPage"
              :nextSoal="nextSoal"
            />
            <PetunjukMenjodohkan
              v-if="item.tipe === 'Menjodohkan'"
              :list="soal"
              :currentPage="currentPage"
              :nextSoal="nextSoal"
            />
            <PetunjukText
              v-if="item.tipe === 'Text'"
              :list="soal"
              :currentPage="currentPage"
              :nextSoal="nextSoal"
              :selectAnswer="selectAnswer"
              :idJadwal="id_jadwal"
              :idJadwalPeserta="id_jadwal_peserta"
            />
          </div>
        </div>
        <div class="soal" v-else>
          <div v-for="item in soal" :key="item.idsoal">
            <PilihanGandaComponent
              v-if="item.tipe === 'Pilihan Ganda' && item.soal_text === null"
              :list="item"
              :currentPage="currentPage"
              :selectAnswer="selectAnswer"
              :nomorUrut="nomorSoal(item.idsoal)"
              @showImage="previewImageToggle"
            />
            <PilihanGandaNarasiComponent
              v-if="item.tipe === 'Pilihan Ganda' && item.soal_text !== null"
              :list="item"
              :currentPage="currentPage"
              :selectAnswer="selectAnswer"
              :nomorUrut="nomorSoal(item.idsoal)"
            />
            <BenarSalahComponent
              v-if="item.tipe === 'Benar Salah'"
              :list="item"
              :currentPage="currentPage"
              :selectAnswer="selectAnswer"
              :nomorUrut="nomorSoal(item.idsoal)"
              @showImage="previewImageToggle"
            />
            <MenjodohkanComponent
              v-if="item.tipe === 'Menjodohkan'"
              :list="soal"
              :currentPage="currentPage"
              :selectAnswer="selectAnswer"
              :nomorUrut="nomorSoal(soal[0].idsoal)"
              @showImage="previewImageToggle"
            />
            <TextComponent
              v-if="item.tipe === 'Text'"
              :list="soal"
              :currentPage="currentPage"
              :selectAnswer="selectAnswer"
              :idJadwal="id_jadwal"
              :idJadwalPeserta="id_jadwal_peserta"
              :nomorUrut="nomorSoal(soal[0].idsoal)"
            />
          </div>
        </div>
      </div>
      <!--      <div class="text-start">=</div>-->
      <div class="text-end" v-if="is_instruksi === 0">
        <!-- <button v-if="last_page - 1 !== index_soal && status_pengerjaan === 0" type="button" class="btn btn-danger m-2" @click="backSoal()" style="width: 200px">Kembali</button> -->
        <button
          v-if="nomor_urut != 1 && tipe == 'Menjodohkan'"
          type="button"
          class="btn btn-danger m-2"
          @click="backSoal()"
          style="width: 200px"
        >
          Kembali
        </button>
        <button
          v-if="last_page - 1 !== index_soal && status_pengerjaan === 0"
          type="button"
          class="btn btn-success m-2"
          @click="nextSoal(1)"
          style="width: 200px"
        >
          Selanjutnya
        </button>
        <button
          v-if="last_page - 1 === index_soal || status_pengerjaan === 1"
          type="button"
          class="btn btn-warning m-2"
          @click="cekSelesai()"
          style="width: 200px"
        >
          Selesai
        </button>
      </div>
    </div>
  </div>
  <FooterPeserta />
  <!-- end row -->
  <!-- </Layout> -->
</template>

<style scoped>
.img-preview {
  max-width: 100vh;
  max-height: 95vh;
}

/* .watermark {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  opacity: 0.3;
} */
.border-none {
  border-style: hidden;
}

.selected {
  background-color: #00f;
  /* Change the background color for selected buttons */
  color: #fff;
  /* Change the text color for selected buttons */
}

.watermark {
  position: fixed;
  z-index: 1;
  font-size: 30pt;
  color: #e18c8c;
  opacity: 0.4;
  font-weight: bold;
}

.watermark1 {
  left: 10%;
  top: 25%;
}

.watermark2 {
  left: 10%;
  bottom: 25%;
}

.watermark3 {
  left: 50%;
  top: 25%;
  transform: translateX(-50%);
}

.watermark4 {
  left: 50%;
  bottom: 25%;
  transform: translateX(-50%);
}

.watermark5 {
  right: 10%;
  top: 25%;
}

.watermark6 {
  right: 10%;
  bottom: 25%;
}

.child-number {
  flex: 1 0 20%;
  margin: 0px 10px;
  max-width: calc(20% - 20px);
}
</style>
