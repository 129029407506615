<script>
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import { ref } from "vue";
import { integer } from "@vuelidate/validators";
import $ from "jquery";

export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {},
  props: {
    list: [],
    nomorUrut: integer,
    currentPage: integer,
    selectAnswer: Function,
  },
  emits: ["showImage"],
  setup(props) {
    const allAnswers = props.list.reduce((accumulator, currentValue) => {
      return [...accumulator, ...currentValue.jawaban];
    }, []);
    // console.log('allAnswers: ', allAnswers);
    // console.log('props.list[0]: ', props.list[0]);

    const items = ref(
      allAnswers.map((item) => {
        if (item.jawaban == props.list[0].jawaban_redis)
          return { ...item, list: 2 };
        return { ...item, list: 1 };
      })
    );

    const getList = (list) => {
      return items.value.filter((item) => item.list == list);
    };

    const startDrag = (event, item) => {
      console.log(item);
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.setData("itemID", item.idjawaban);
    };

    const onDrop = (event, list) => {
      const itemID = event.dataTransfer.getData("itemID");
      const item = items.value.find((item) => item.idjawaban == itemID);
      if (list !== 1 && getList(list).length > 0) {
        console.log(`Hanya satu jawaban yang diizinkan untuk masuk!`);
        return;
      }
      console.log(list);
      console.log(props.list[0]);
      if (typeof props.selectAnswer === "function") {
        props.selectAnswer(props.list[0], item);
      }
      item.list = list;
    };

    return {
      getList,
      onDrop,
      startDrag,
    };
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Dashboards",
          href: "/",
        },
        {
          text: "Default",
          active: true,
        },
      ],
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      user_name: localStorage.session_name,
      pathPhoto: localStorage.session_path_photo,
      pathPhotoFull:
        process.env.VUE_APP_BACKEND_URL + localStorage.session_path_photo,
      modalProgress: false,
      link: process.env.VUE_APP_BACKEND_URL,
    };
  },
  methods: {
    selesai() {
      Swal.fire({
        icon: "warning",
        title: "Konfirmasi!",
        text: "Apakah Anda Yakin?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Ya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            icon: "success",
            title: "Selesai!",
            text: "Jawaban berhasil di simpan",
            timer: 2000,
            timerProgressBar: true,
          });
          this.modalProgress = false;
        } else {
          this.modalProgress = false;
        }
      });
    },
    setHeightDropZoneJawaban() {
      let heightPilihan = $(".pilihan-zone").height();
      $(".jawaban-zone").height(heightPilihan);
    },
    preview(url) {
      this.$emit("showImage", url);
    },
  },
  mounted() {
    this.setHeightDropZoneJawaban();
    // const self = this;
  },
  updated() {
    // this.setHeightDropZoneJawaban();
    const self = this;
    $(".drag-el > img")
      .addClass("image-soal")
      .wrap("<div class='container-image-soal'></div");
    // $(".image-soal").after(
    //   `<div
    //     class="middle d-flex flex-column gap-2 align-items-center justify-content-center"
    //   >
    //     <button
    //       class="btn btn-success btn-sm preview-btn"
    //     >
    //       <i class="fa fa-search"></i> Preview
    //     </button>
    //   </div>`
    // );

    $(".image-soal").click(function () {
      self.previewImage = true;
      self.urlSrc = $(this).attr("src");
      self.$emit("showImage", $(this).attr("src"));
      console.log($(this).attr("src"), self);
    });
  },
};
</script>

<template>
  <!--  <img-->
  <!--    v-if="list.soal_image"-->
  <!--    :src="link + list[0].soal_image"-->
  <!--    style="max-width: 70vw"-->
  <!--    class="mx-auto mb-2"-->
  <!--  />-->
  <!--  <div v-if="list[0].soal_text !== null" class="card mb-2">-->
  <!--    <div class="card-body bg-light" style="border-radius: 5px">-->
  <!--      {{ list[0].soal_text }}-->
  <!--    </div>-->
  <!--  </div>-->
  <!--  <div class="col-xl-12">-->
  <!--    <div class="card border card-soal">-->
  <!--      <div class="card-body body-soal">-->
  <!--        <h5>Pertanyaan :</h5>-->
  <!--        {{ list[0].soal_text }}-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
  <div class="row my-2">
    <div class="col-xl-8">
      <div class="card h-100">
        <div class="card-body border" style="border-radius: 5px">
          <table
            class="table align-middle table-striped table-bordered font-size-14 h-100"
          >
            <thead class="table-primary">
              <tr>
                <td width="5%" style="text-align: center">No</td>
                <td width="50%">Soal</td>
                <td width="35%">Jawab</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="soal in list" :key="soal.idsoal">
                <td width="5%" style="text-align: center; vertical-align: top">
                  {{ nomorUrut }}
                </td>
                <td width="50%" style="vertical-align: top">
                  {{ soal.pertanyaan }}

                  <br />
                  <br />
                  <div class="w-100 text-center">
                    <div
                      class="container-image-soal"
                      style="margin: auto"
                      v-if="soal.soal_image"
                    >
                      <img
                        v-if="soal.soal_image"
                        :src="link + soal.soal_image"
                        class="image"
                      />
                      <div
                        class="middle d-flex flex-column gap-2 align-items-center justify-content-center"
                      >
                        <button
                          class="btn btn-success btn-sm"
                          @click="preview(link + soal.soal_image)"
                        >
                          <i class="fa fa-search"></i> Preview
                        </button>
                      </div>
                    </div>
                  </div>
                </td>
                <td width="35%" style="vertical-align: top">
                  <div
                    class="drop-zone jawaban-zone h-100"
                    style="border-radius: 5px"
                    @drop="onDrop($event, 2)"
                    @dragenter.prevent
                    @dragover.prevent
                    @dragleave="onDragLeave"
                  >
                    <div
                      v-for="item in getList(2)"
                      :key="item.idjawaban"
                      style="border-radius: 5px"
                      class="drag-el"
                      draggable="true"
                      @dragstart="startDrag($event, item)"
                      v-html="item.jawaban"
                    ></div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- <div class="text-center">
          </div> -->
        </div>
      </div>
    </div>
    <div class="offset-xl-1 col-xl-3">
      <div class="card">
        <div class="card-body border" style="border-radius: 5px">
          <div
            class="drop-zone pilihan-zone h-100"
            style="border-radius: 5px"
            @drop="onDrop($event, 1)"
            @dragenter.prevent
            @dragover.prevent
          >
            <div
              v-for="item in getList(1)"
              :key="item.idjawaban"
              style="border-radius: 5px"
              class="m-2"
              draggable="true"
              @dragstart="startDrag($event, item)"
            >
              <div class="drag-el" v-html="item.jawaban"></div>
              <!-- {{ item.jawaban }} -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.drop-zone {
  background-color: #ecf0f1;
  padding: 5px;
  min-height: 100px;
  text-align: center;
}

.drag-el {
  background-color: #556ee6;
  color: white;
  padding: 5px;
}

.card-soal {
  background-color: #ecf0f1;
}

.body-soal {
  border-radius: 5px;
  padding: 5px;
  margin: 10px;
}

img {
  text-align: center;
  width: 80%;
  max-width: 500px;
}
</style>

<style>
.drag-el img {
  text-align: center;
  width: 80% !important;
  max-width: 500px;
}

/* .drag-el > img {
  text-align: center;
  width: 80% !important;
  max-width: 500px;
} */
</style>
